import React, { useEffect } from "react";
import Navbar from "./navbar";
import Slider from "./slider";
import { Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";

export default function Home() {
  useEffect(() => {
    const sesh = Cookies.get("sesh_u");
    async function checker() {
      await fetch(
        `https://api.tierslimited.com/check?sesh_u=${sesh}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "invalid user") {
            window.location.href = "/";
          } else {
            return;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    checker();
  }, []);
  return (
    <div>
      <Navbar />
      <Slider />
      <center>
        <Row className="intro" style={{ width: "85%" }}>
          <Col md={6} style={{ marginTop: "8%" }}>
            <h3 style={{ color: "#1d3768", textAlign: "left" }}>
              Welcome to TIERS Limited
            </h3>
            <p style={{ textAlign: "justify", color: "#adaeb9" }}>
              Welcome to our software agency! We are a team of skilled software
              developers, designers, and project managers who are passionate
              about building high-quality software solutions for our clients.
              With years of industry experience and a proven track record of
              delivering successful projects, we have the expertise to turn your
              ideas into reality. Our process starts with understanding your
              business needs and goals, and then we work with you to design and
              develop a custom software solution that meets those needs. We
              pride ourselves on our ability to understand our clients' needs
              and provide them with the best possible solution. We are committed
              to building long-lasting relationships with our clients and
              helping them achieve success through the use of technology. Thank
              you for considering us for your software development needs. We
              look forward to working with you!
            </p>
          </Col>
          <Col md={6} style={{ marginTop: "8%" }}>
            <img
              src="./Assets/slider2.jpg"
              alt=""
              style={{ width: "96%", height: "320px", borderRadius: "5px" }}
            />
          </Col>
        </Row>
      </center>
      <center>
        <Row className="intro" style={{ width: "85%", marginBottom: "60px" }}>
          <Col md={6} style={{ marginTop: "8%" }}>
            <img
              src="./Assets/verification.png"
              alt=""
              style={{ width: "96%", height: "320px", borderRadius: "5px" }}
            />
          </Col>
          <Col md={6} style={{ marginTop: "8%" }}>
            <h3 style={{ color: "#1d3768", textAlign: "left" }}>
              Verification Portal ADMIN
            </h3>
            <p style={{ textAlign: "justify", color: "#adaeb9" }}>
              we provide an easy and efficient way for our interns to access and
              verify their internship credentials. Whether you are looking to
              download your internship certificate or request a letter of
              internship, this portal has got you covered. If you have
              successfully completed your internship with us, you can download
              your official internship certificate from this portal. The
              certificate validates your experience and accomplishments during
              your time at TIERS Limited. Need a letter confirming your
              internship details for your future endeavors? No worries! You can
              request a personalized letter of internship through this portal.
              Our team will generate the letter with all the relevant details.
              As part of our commitment to transparency and authenticity, we
              also offer an easy way for third parties to verify the
              authenticity of TIERS Limited internship certificates. Internship
              providers, employers, and other stakeholders can use this portal
              to verify the validity of our interns' credentials.
            </p>
          </Col>
        </Row>
      </center>
    </div>
  );
}
