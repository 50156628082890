import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBInput,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function View() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const sesh = Cookies.get("sesh_u");
    async function checker() {
      await fetch(
        `https://notch-cactus-vacuum.glitch.me/check?sesh_u=${sesh}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "invalid user") {
            window.location.href = "/";
          } else {
            return;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    checker();

    async function getcert() {
      await fetch(`https://notch-cactus-vacuum.glitch.me/getcertificate`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    getcert();
  }, []);

  async function deletecert(id) {
    if (window.confirm("Are you Sure you want to Delete?")) {
      await fetch(`https://notch-cactus-vacuum.glitch.me/deletecert?id=${id}`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "deleted") {
            async function getcert() {
              await fetch(
                `https://notch-cactus-vacuum.glitch.me/getcertificate`,
                {
                  method: "GET",
                  headers: {
                    "api-key": process.env.REACT_APP_API_KEY,
                  },
                }
              )
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Request failed.");
                  }
                  return response.json();
                })
                .then((data) => {
                  setData(data.data);
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            }

            getcert();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <div>
      <Navbar />
      <h4 style={{ marginTop: "40px", color: "#1d3768" }}>
        Created Certificates & Internship Letters
      </h4>
      <center>
        <div style={{ margin: "30px", overflowY: "auto" }}>
          <MDBTable hover>
            <MDBTableHead style={{ backgroundColor: "#fafafa" }}>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
                <th scope="col">Dated</th>
                <th scope="col">Certificate</th>
                <th scope="col">Letter</th>
                <th scope="col">Delete</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data.map((item, index) => (
                <tr>
                  <th scope="row">{item.cid}</th>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.contact}</td>
                  <td>{new Date(item.dated).toLocaleString()}</td>
                  <td>{index}</td>
                  <td>
                    <a
                      href={index < 70 ? `https://notch-cactus-vacuum.glitch.me/uploads/${item.certificate}`: `https://api.tierslimited.com/uploads/${item.certificate}`}
                      target="_blank"
                    >
                      Certificate
                    </a>
                  </td>
                  <td>
                    <a
                      href={index < 70 ? `https://notch-cactus-vacuum.glitch.me/uploads/${item.letter}` : `https://api.tierslimited.com/uploads/${item.letter}`}
                      target="_blank"
                    >
                      Letter
                    </a>
                  </td>
                  <td>
                    <i
                      className="fa fa-trash"
                      style={{ color: "red" }}
                      onClick={() => {
                        deletecert(item.Id);
                      }}
                    ></i>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>{" "}
        </div>
      </center>
    </div>
  );
}
