import './App.css';
import {Switch,Route} from 'react-router-dom';
import Login from './Components/login';
import Home from './Components/home';
import Certificate from './Components/certificate';
import View from './Components/view';
import Topers from './Components/topers';
import ViewToper from './Components/view-topers';
function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/home" component={Home}/>
        <Route exact path="/certificates" component={Certificate}/>
        <Route exact path="/view-certificates" component={View}/>
        <Route exact path="/add-toper-performers" component={Topers}/>
        <Route exact path="/view-topers" component={ViewToper}/>
      </Switch>
    </div>
  );
}

export default App;
