import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

export default function Navbar() {
  const [showBasic, setShowBasic] = useState(false);
  const [years,setYears]=useState([]);

  useEffect(()=>{
    getYearsArray();
  },[])

  function getYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const yearsArray = [];
  
    for (let year = startYear; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    
    setYears(yearsArray);
  }

  return (
    <MDBNavbar expand="lg" light sticky style={{ backgroundColor: "white", padding:"30px" }}>
      <MDBContainer fluid>
        <MDBNavbarBrand
          href="#"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src="/Assets/main.jpg"
            alt=""
            width="180px"
            height="45px"
            style={{ marginTop: "-5px" }}
          />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
          style={{ border: "none" }}
        >
          <MDBIcon icon="bars" fas style={{ color: "rgb(29, 55, 104)" }} />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav
            className="mr-auto mb-2 mb-lg-0"
            style={{ flex: 1, justifyContent: "center" }}
          >
            <MDBNavbarItem>
              <MDBNavbarLink
                active
                aria-current="page"
                href="/home"
                style={{ color: "rgb(29, 55, 104)" }}
              >
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                active
                aria-current="page"
                href="/certificates"
                style={{ color: "rgb(29, 55, 104)" }}
              >
                Certificates & Letters
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink
                active
                aria-current="page"
                href="/view-certificates"
                style={{ color: "rgb(29, 55, 104)" }}
              >
                View Certificates & Letters
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink
                active
                aria-current="page"
                href="/add-toper-performers"
                style={{ color: "rgb(29, 55, 104)" }}
              >
                Add Top Performers
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  className="nav-link"
                  role="button"
                  style={{ color: "rgb(29, 55, 104)" }}
                >
                  Top Performers
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {years.map((item,index)=>(
                  <MDBDropdownItem link href={`/view-topers?year=${item}`}>
                    {item}
                  </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>

          <MDBIcon
            onClick={() => {
              Cookies.remove("sesh_u");
              window.location.href = "/";
            }}
            icon="sign-out-alt"
            fas
            size="lg"
            style={{ color: "rgb(29, 55, 104)" }}
          />
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
