import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import {useLocation} from 'react-router-dom';

export default function ViewToper() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('year');

  useEffect(() => {
    const sesh = Cookies.get("sesh_u");
    async function checker() {
      await fetch(
        `https://api.tierslimited.com/check?sesh_u=${sesh}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "invalid user") {
            window.location.href = "/";
          } else {
            return;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    checker();
    gettoper();
  }, []);

  async function gettoper() {
    await fetch(`https://api.tierslimited.com/gettopers?year=${paramValue}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function deletetoper(id) {
    if (window.confirm("Are you sure you want to Delete?")) {
      await fetch(
        `https://api.tierslimited.com/deletetoper?id=${id}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          gettoper();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  return (
    <div>
      <Navbar />
      <h3 style={{ marginTop: "40px", color: "#1d3768" }}>
        Top Performer {paramValue}
      </h3>
      <center>
        <MDBContainer className="py-5">
          <MDBRow className="text-center">
            {data.map((item, index) => (
              <MDBCol md="4" className="mb-5 mb-md-0">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src={`https://api.tierslimited.com/uploads/${item.image}`}
                    className="rounded-circle shadow-1-strong"
                    width="150"
                    height="150"
                  />
                </div>
                <h5 className="mb-3">{item.name}</h5>
                <h6 className="text-primary mb-3">{item.email}</h6>
                <p className="px-xl-3">{item.contact}</p>
                <p className="px-xl-3">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  {item.message}
                </p>
                <p className="px-xl-3">
                  {new Date(item.dated).toLocaleString()}
                </p>
                <i
                  className="fa fa-trash"
                  style={{ color: "red" }}
                  onClick={() => {
                    deletetoper(item.Id);
                  }}
                ></i>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </center>
    </div>
  );
}
