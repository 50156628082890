import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setSubmit(true);
    await fetch(
      `https://api.tierslimited.com/login?email=${email}&password=${password}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "incorrect") {
          setSubmit(false);
          document.getElementById("login-error").innerHTML =
            "Incorrect Username or Password";
          document.getElementById("login-error").style.display = "block";
          document.getElementById("login-error").style.color = "red";
        } else {
          if (data.message == "start") {
            const expirationTime = 1;
            const expirationDate = new Date();
            expirationDate.setTime(
              expirationDate.getTime() + expirationTime * 60 * 60 * 1000
            );
            Cookies.set("sesh_u", data.sesh_u, { expires: expirationDate });
            window.location.href = `${process.env.REACT_APP_ADMIN}`;
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <MDBContainer fluid>
      <MDBRow className="vh-100 justify-content-center align-items-center">
        <MDBCol col="12">
          <MDBCard
            className="bg-white my-5 mx-auto"
            style={{
              borderRadius: "1rem",
              maxWidth: "500px",
            }}
          >
            <MDBCardBody className="p-5 w-100 d-flex flex-column">
              <center>
                <img
                  src="./Assets/logo.png"
                  alt=""
                  style={{ width: "100px" }}
                />
                <h2 className="fw-bold mb-2 text-center">Sign in</h2>
              </center>
              <form onSubmit={handleLogin}>
                <div style={{ marginTop: "30px" }}>
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Email address"
                    id="email"
                    type="email"
                    size="lg"
                    value={email}
                    required
                    onChange={handleEmail}
                  />
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Password"
                    id="password"
                    type="password"
                    size="lg"
                    required
                    value={password}
                    onChange={handlePassword}
                  />
                </div>
                <span id="login-error" style={{ marginTop: "-10px" }}></span>
                {submit ? (
                  <MDBBtn
                    type="submit"
                    style={{
                      backgroundColor: "#1d3768",
                      border: "none",
                      fontWeight: "bold",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    <MDBSpinner color="info"></MDBSpinner>
                  </MDBBtn>
                ) : (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#1d3768",
                      border: "none",
                      fontWeight: "bold",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    Login
                  </Button>
                )}
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
