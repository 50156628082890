import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import { Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { MDBRow, MDBCol, MDBInput, MDBSpinner, MDBBtn } from "mdb-react-ui-kit";
import toast,{Toaster} from "react-hot-toast";

export default function Certificate() {
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const sesh = Cookies.get("sesh_u");
    async function checker() {
      await fetch(
        `https://api.tierslimited.com/check?sesh_u=${sesh}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "invalid user") {
            window.location.href = "/";
          } else {
            return;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    checker();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmit(true);
    const form = document.getElementById("certificate");
    const formData = new FormData(form);
    await fetch("https://api.tierslimited.com/certificate", {
      method: "POST",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "added") {
          setSubmit(false);
          toast.success('Certificate Added');
          form.reset();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Navbar />
      <h1 style={{ marginTop: "40px", color: "#1d3768" }}>
        CREATE A CERTIFICTAE
      </h1>
      <center>
        <form
          style={{ textAlign: "left", width: "80%", marginTop: "60px" }}
          id="certificate"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <MDBRow className="align-items-center">
            <MDBCol md="6">
              <label htmlFor="">Intern Name</label>
              <MDBInput id="name" name="name" required />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="">Certificate Id</label>
              <MDBInput id="id" name="id" required />
            </MDBCol>
          </MDBRow>

          <MDBRow className="align-items-center">
            <MDBCol md="6">
              <label htmlFor="">Email</label>
              <MDBInput id="email" name="email" type="email" required />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="">Contact</label>
              <MDBInput id="contact" required name="contact" />
            </MDBCol>
          </MDBRow>

          <MDBRow className="align-items-center">
            <MDBCol md="6">
              <label htmlFor="">Certificate</label>
              <MDBInput
                id="certificate"
                required
                name="certificate"
                type="file"
              />
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="">Internship Letter</label>
              <MDBInput id="letter" required type="file" name="letter" />
            </MDBCol>
          </MDBRow>

          {submit ? (
            <MDBBtn
              type="submit"
              style={{
                backgroundColor: "#1d3768",
                border: "none",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
              }}
            >
              <MDBSpinner color="info"></MDBSpinner>
            </MDBBtn>
          ) : (
            <Button
              type="submit"
              style={{
                backgroundColor: "#1d3768",
                border: "none",
                fontWeight: "bold",
                color: "white",
                marginTop: "30px",
              }}
            >
              Publish
            </Button>
          )}
        </form>
      </center>

      <Toaster/>
    </div>
  );
}
